import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { CommonModule } from "@angular/common";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

/*%IMPORTS%*/import { PageComponent__ssh } from "./pages/PageComponent__ssh_component";import { PageComponent__index } from "./pages/PageComponent__index_component";import { PageComponent__gpg } from "./pages/PageComponent__gpg_component";import { PageComponent__dev } from "./pages/PageComponent__dev_component";import { PageComponent___layout } from "./pages/PageComponent___layout_component";

@NgModule({
  declarations: [
    /*%DECLARATIONS%*/PageComponent__ssh,PageComponent__index,PageComponent__gpg,PageComponent__dev,PageComponent___layout,
    AppComponent
  ],
  imports: [
    CommonModule,
    BrowserModule.withServerTransition({ appId: "serverApp" }),
    BrowserAnimationsModule,
    FormsModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent],
  entryComponents: []
})
export class AppModule {}
