import { Component, OnInit, OnDestroy, AfterViewInit, Input, NgZone, ElementRef, ViewChild } from "@angular/core";
import { Title, Meta, DomSanitizer } from "@angular/platform-browser";
import {ViewportScroller} from "@angular/common";
import {
  trigger,
  state,
  style,
  animate,
  transition,
  keyframes,
  query,
  stagger,
  group,
  sequence,
  AnimationBuilder,
} from "@angular/animations";
import { Router } from "@angular/router";

@Component({
    /*%SELECTOR%*/
    templateUrl: "./PageComponent__index_component.html",
    styleUrls: ["./PageComponent__index_component.scss"],
    host: {
        /*%HOST%*/
    },
    animations: [
        /*%ANIMATIONS%*/
    ],
})
export class PageComponent__index implements OnInit, OnDestroy, AfterViewInit {
    constructor(private title: Title,
                private meta: Meta,
                public sanitizer: DomSanitizer,
	            public viewportScroller: ViewportScroller,
                private ngZone: NgZone,
                private elementRef: ElementRef,
                public animationBuilder: AnimationBuilder,
                public router: Router,
    ) { }
    
    ngOnInit(): void {
        /*%INIT%*/
						this.title.setTitle("Chris Smith");
						this.meta.updateTag({property: "og:title", content: "Chris Smith"});
						this.meta.updateTag({name: "description", content: ""});
						this.meta.updateTag({property: "og:description", content: ""});
					
    }

    ngAfterViewInit(): void {
        /*%DISPLAYED%*/
    }

    ngOnDestroy(): void {
        /*%DESTROY%*/
    }

    /*%BODY%*/
}
