import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

/*%IMPORTS%*/import { PageComponent__ssh } from "./pages/PageComponent__ssh_component";import { PageComponent__index } from "./pages/PageComponent__index_component";import { PageComponent__gpg } from "./pages/PageComponent__gpg_component";import { PageComponent__dev } from "./pages/PageComponent__dev_component";import { PageComponent___layout } from "./pages/PageComponent___layout_component";

const routes: Routes = [
  /*%ROUTES%*/{ path:"", component:PageComponent___layout, children:[{ path:"dev", component:PageComponent__dev, children:[] },{ path:"gpg", component:PageComponent__gpg, children:[] },{ path:"", component:PageComponent__index, children:[] },{ path:"ssh", component:PageComponent__ssh, children:[] },] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: "enabled"
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
